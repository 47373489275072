.team_item{
    display:grid;
    grid-template-columns: 1fr 2fr 1fr;
    align-items: center;
    padding: var(--padding-small);
    background: var(--background);
    color: white;
    box-shadow: 0 1px 5px 0 white, 0 1px 3px 0 rgba(0, 0, 0, 0.1);
    border-radius: 7px;
    margin-bottom: 10px;
}
.team_item:hover {
    background: var(--hover);
    border-radius: 7px;
    box-shadow: 0 1px 5px 0 var(--primary), 0 1px 3px 0 rgba(0, 0, 0, 0.1);
}
.team_item_logo {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
a:link, a:visited, a:active {
    text-decoration:none;
    color: white;
}
.team_item h1 {
    font-size: 3em;
    text-align: center;
    font-family:Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}
.team_item img {
    height: 7vh;
    width: 7vh;
    margin: 0.3em auto;
    border-radius: 50%;
}
.points{
    text-align: center;
}
@media (max-width: 600px) {
    .team_item{
        border-radius: 5px;
        margin-bottom: 7px;
        padding: var(--padding-small);
        margin: var(--margin) 0px;
        box-shadow: 0 1px 5px 0 var(--primary), 0 1px 3px 0 rgba(0, 0, 0, 0.1);
    }
    .team_item_logo {
        display: flex;
        flex-direction: column;
    }
    .team_item h1 {
        font-size: 3em;
    }
    .team_item h3 {
        font-size: 1em;
    }
    .team_item img {
        height: 6vh;
        width: 6vh;
        margin: auto;
        border-radius: 50%;
    }
    p {
        font-size: 14px;
    }
}