.tournament_detail_section {
    width: 100%;
    margin: 1em 0em;
}

.tournament_detail_grid {
    display: grid;
    grid-template-columns: 8fr 3fr;
    margin: 1.5em 0em;
}

.tournament_detail {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--hover);
    color: white;
    padding: 0.5em;
    margin: 0em 0em;
}

.tournament_detail_nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 80%;
}

.tournament_detail_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: var(----padding-small);
}

.tournament_detail_container h1 {
    font-size: 5em;
    text-align: center;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}

.fotomapa_detail {
    height: 40vh;
    width: 100%;
    margin: auto;
    border-radius: 5px;
    margin: var(--margin);
    box-shadow: 0 2px 5px 0 var(--text), 0 1px 3px 0 rgba(0, 0, 0, 0.1);
}

.tournament_detail_date {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
}

.tournament_detail_date * {
    margin: 0.5em 1em;
}

.tournamentdetail_row {
    display: flex;
    flex-direction: column;
    align-items: left;
    width: 70%;
}

.tournamentdetail_row * {
    margin: 0.5em 1em;
    width: 90%;
}

.tournamentdetail_teams {
    margin: 1em 0em;
    width: 90%;
}

.num-teams {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
}

.tournamentdetail_player {
    margin-left: 2em;
}

.tournament_detail_match {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--card);
    border: dashed var(--dorado) 1px;
    margin-bottom: 1em;
    padding: var(--padding-small);
}

.tournament_detail_match * {
    margin: 0.3em;
}

.tournament_detail_options {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tournament_detail_copy {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.tournament_detail_winner {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tournament_detail_logovictoria {
    height: 16vh;
    width: 12vh;
    margin: 1.2em;
    border-radius: 20%;
}

@media (max-width: 600px) {
    .tournament_detail_section {
        margin: 0em 0em;
    }
    .tournament_detail {
        margin: 0em;
    }
    .tournament_detail_grid {
        display: flex;
        flex-direction: column;
    }
    .tournament_detail_container {
        display: flex;
        flex-direction: column;
        align-items: left;
        padding: var(----padding-small);
    }

    .tournament_detail_container * {
        text-align: center;
        margin: 0.3em 0px;
    }

    .tournament_detail_date {
        width: 100%;
    }

    .fotomapa_detail {
        height: 25vh;
        width: 40vh;
        margin: var(--margin-small);
    }

    .tournamentdetail_row {
        display: flex;
        flex-direction: column;
        align-items: left;
        margin: 0.5em 0px;
        width: 100%;
    }

    .tournamentdetail_row * {
        margin: 0.2em 2em;
    }

    .num-teams {
        text-align: left;
    }

    .tournamentdetail_player {
        margin: 3px 1em;
    }

    .tournamentdetail_player * {
        color: var(--text);
        font-size: 12px;
        padding: 0px;
    }

    .tournamentdetail_player .player_item {
        margin: 3px 0px;
    }

    .tournament_detail_logovictoria {
        height: 12vh;
        width: 8vh;
        margin: 0.0em;
        border-radius: 20%;
    }
}