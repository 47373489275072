.comment {
    border-radius: 10px;
    padding: 1.2em 0.7em;
    margin: 0.5em 0.5em;
    font-size: 14px;
    background-color: var(--background);
}
.comment_autor {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    gap: 5px;
    align-items: center;
}
@media (max-width: 600px) {
    .comment {
        margin: 0.3em auto;
        width: 80%;
    }
}