* {
  margin: 0px;
  font-family: "Source Sans Pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0px;
}

:root {
  /*colors*/
  --background: #090909;
  --hover: #111111;
  --card: #141414;
  --text: #dae1e6;
  --primary: #7ADA00;
  --secondary: #FFCA00;
  --warning: #ffc107;
  --dorado: #EFB810;
  /*spacing*/
  --padding-small: 5px;
  --padding: 10px;
  --padding-large: 20px;
  --margin-small: 5px;
  --margin: 10px;
  --margin-large: 20px;
  --radius: 2px;
  --radius-large: 5px;
  --border: 2px;
  /*sizes*/
  --logo: 100px;
  --icon: 45px;
  --button-square: 50px;
  --action-icon: 25px;
  --action-icon-hover: 30px;
  --font-small: 0.7em;
}