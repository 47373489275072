.form_jointournament {
    width: 100%;
}

.form_jointournament_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--card);
    color: white;
    font-size: 16px;
    font-weight: bold;
    width: 100%;
    padding: 1em 1em;
    margin: 1.5em auto;
}
.form_jointournament {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 7px;
    width: 100%;
}
.jointournament_detail_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1em 0em;
}
.jointournament_detail_container h3{
    font-size: 1.3em;
}
.form_jointournament_row {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    text-align: left;
    width: 100%
}
.form_jointournament_row * {
    margin: 1em;
    text-align: left;
}

label {
    margin: 1em;
}
.fotomapa{
    height: 40vh;
    width: 70vh;
    margin: 1em auto;
    border-radius: 5px;
    box-shadow: 0 2px 5px 0 var(--text), 0 1px 3px 0 rgba(0, 0, 0, 0.1);
}

@media (max-width: 600px) {
    .form_jointournament_container {
        font-weight: bold;
        font-size: 14px;
        width: 100%;
        padding: 0em 0em;
    }
    .tournament_detail_container{
        display: flex;
        flex-direction: column;
        align-items: left;
        padding: var(----padding-small); 
    }
    .jointournament_detail_container h5 {
        font-size: 1em;
    }
    .fotomapa {
        height: 25vh;
        width: 40vh;
        margin: 1em 0px;
    }
    .jointournamentdetail_row{
        display: flex;
        flex-direction: column;
        align-items: left;
        margin: 0.5em 0px;   
    }
    .jointournamentdetail_row *{
        margin: 0.0em 2em;   
    }
}