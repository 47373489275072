.snippet {
  position: relative;
  padding: 2rem 5%;
  margin: 1.5rem 0;
  box-shadow: 0 .4rem .8rem -.1rem rgba(0, 32, 128, .1), 0 0 0 1px #f0f2f7;
  border-radius: .25rem;
}

.examples .snippet:before {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 5px;
  content: attr(data-title);
  font-size: .75rem;
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  color: white;
  background-color: rgb(255, 25, 100);
  border-radius: .25rem 0 .25rem 0;
}

.stage {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 2rem 0;
  margin: 0 -5%;
  overflow: hidden;
  margin-top: 50px;
}

.filter-contrast {
  filter: contrast(3);
}

/**
 * ==============================================
 * Experiment-Gooey Effect
 * Dot Overtaking
 * ==============================================
 */
.dot-overtaking {
  position: relative;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: transparent;
  color: var(--primary);
  margin: -1px 0;
  box-shadow: 0 -20px 0 0;
  animation: dotOvertaking 2s infinite cubic-bezier(0.2, 0.6, 0.8, 0.2);
}

.dot-overtaking::before,
.dot-overtaking::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: transparent;
  color: var(--primary);
  box-shadow: 0 -20px 0 0;
}

.dot-overtaking::before {
  animation: dotOvertaking 2s infinite cubic-bezier(0.2, 0.6, 0.8, 0.2);
  animation-delay: .3s;
}

.dot-overtaking::after {
  animation: dotOvertaking 1.5s infinite cubic-bezier(0.2, 0.6, 0.8, 0.2);
  animation-delay: .6s;
}

@keyframes dotOvertaking {
  0% {
    transform: rotateZ(0deg);
  }

  100% {
    transform: rotateZ(360deg);
  }
}