.comments {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--hover);
    color: white;
    padding: 16em 0em;
    margin: 0em 0em;
}
.form_comments {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
}
.form_comments_label {
    font-size: 14px;
    margin: 0em;
}
.form_comments_textarea {
    overflow: none;
    padding: var(--padding-small);
    width: 95%;
    margin: 0.5em 0em;
}
@media (max-width: 600px) {
    .comments {
        padding: 3em 0.7em;
        margin: 0em 0em;
    }
    .form_comments_textarea {
        overflow: none;
        padding: var(--padding-small);
        margin: 0.5em 0em;
    }
}