.chat_detail{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url(../../../public/fondo-chat-mobile.png);
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
    padding: 1em;
    height: 75vh;
}
.chat_detail_container {
    display: grid;
    grid-template-rows: 6fr 1fr;
    font-size: 22px;
    font-weight: bold;
    width: 100%;
    height: 100%;
}
.chat_detail_inferior {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    margin: 0.8em 0em;
}
.chat_detail_input {
    border-radius: 5px;
    border: 3px solid var(--card);
    background-color: white;
    width: 100%;
}
.btn_chat_detail {
    padding: 0.2em 0.5em;
    margin-left: 0.5em;
    border: solid black 2px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    background-color: var(--primary);
}
.chat_item {
    border-radius: 10px;
    padding: 1.2em 0.7em;
    margin: 0.5em 0.5em;
    font-size: 14px;
    max-width: 25vh;
    background-color: var(--background);
    box-shadow: 0 1px 5px 0 var(--text), 0 1px 3px 0 rgba(0, 0, 0, 0.1);
}
.chat_author {
    color: var(--text);
}
.chat_message {
    font-weight: lighter;
}

@media (max-width: 600px) {
    .chat_detail {
        width: 100%;
        margin: 1.5em auto;
    }
    .btn_chat_detail {
        font-size: 14px;
    }
    .chat_item {
        margin: 0.3em auto;
        width: 95%;
        max-width: 45vh;
    }
}
