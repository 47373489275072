
footer {
    display: flex;
    justify-content: space-between;
    padding: var(--padding-large);
    align-items: center;
    background-color: var(--background);
    grid-area: footer;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1000;
    transition: bottom 1s cubic-bezier(0.77, 0, 0.175, 1);
}
.footer-details {
    width: 100%;
}
.footer-details > a:hover{
    color: white;
}
.footer-links {
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.footer-links a{
    margin: 0px 0.3em;
    text-decoration: none;
    color: white;
}
.footer-links a:hover, .nav-links a:focus{
    margin: 0px 0.3em;
    text-decoration: none;
    color: var(--primary);
}
.hidden {
    display: none;
}
@media (max-width: 600px) {
    footer {
        display: flex;
        justify-content: space-between;
        padding: var(--padding);
    }

}