.joinTeam {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: var(----padding);
    background: var(--card);
    color: white;
    border-radius: 7px;
    margin: 5em auto;
    padding: 1em;
    width: 30%;
}
.joinTeam *{
    padding: var(--padding);
}
.joinTeam_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.joinTeam_container * {
    margin: 1em 0em;
}
@media (max-width: 600px) {
    .joinTeam {
        width: 90%;
    }

}