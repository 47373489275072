.form_tournament_container {
    margin: 1em auto;
    width: 35%;
}
.form_tournament {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--card);
    color: white;
    font-size: 16px;
    font-weight: bold;
    padding: 1em 1em;
    width: 100%;
}
.form_tournament_row {
    display: flex;  
    justify-content: space-between;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.form_tournament_row * {
    margin: 1em;
    width: 100%;
    text-align: center;
}
label {
    text-align: left;
}
.form_tournament_textarea {
    overflow:none;
    padding: var(--padding-small);
    width: 200%;
}
@media (max-width: 600px) {
    .form_tournament_container {
        width: 100%;
    }
    .form_tournament {
        font-size: 14px;
        font-weight: bold;
        padding: 0em 0em;
    }
    .form_tournament {
        margin: 10px 0px;
    }
}