.form_team_container {
    margin: 1em auto;
    width: 30%;
}
.form_team {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--card);
    color: white;
    font-size: 16px;
    font-weight: bold;
    margin: 1em auto;
    padding: 1em 1em;
    width: 100%;
}
.form_team_row {
    display: flex;  
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.form_team_row *{
    margin: 15px;
}
.form_team_row input {
    width: 90%;
    text-align: center;
}
label {
    text-align: left;
}
.form_team_textarea {
    overflow:none;
    padding: var(--padding-small);
    width: 200%;
}
.span_logo {
    font-weight: lighter;
}
@media (max-width: 600px) {
    .form_team_container {
        width: 100%;
        margin: 1em auto;
    }
    .form_team {
        font-size: 14px;
        font-weight: bold;
        padding: 0em 0em;
    }
    .form {
        margin: 0px;
    }
}