.forms {
    margin: 1em auto;
}
.form_buttons {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 600px) {
    .forms {
        width: 100%;
        margin: 1em auto;
    }

}