.login_item {
    width: 100%;
}
.login_item form{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.login_item_row {
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: center;
}