.tournaments {
    width: 100%;
    margin: 1em 0em;
}
.tournaments_section {
    width: 100%;
    margin: 1.5em 0em;
}
@media (max-width: 600px) {
    .tournaments {
        margin: 0.3em 0em;
    }
    .tournaments_section {
        width: 99.5%;
        margin: 1.5em 1px;
    }
}