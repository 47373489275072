.login_item{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: var(----padding);
    background: var(--card);
    color: white;
    border-radius: 7px;
    margin: 5em auto;
    padding: 1em;
    width: 30%;
}
.login_item *{
    padding: var(--padding);
}
.login_item_form {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.btn_popup_nick{
    padding: 5px;
    border: solid black 3px;
    border-radius: 7px;
    font-size: 16px;
    font-weight: bold;
    background-color:  var(--primary);
    color: black;
}
.btn_popup_nick:hover{
    border-radius: 8px;
    border: solid black 4px;
    color: black;
}
.login_item_nickocupado {
    color: red;
}
@media (max-width: 600px) {
    .login_item {
        width: 90%;
    }
    .btn_popup_nick{
        border: solid black 2px;
        border-radius: 5px;
        font-size: 12px;
        padding: 0.4em;
    }

}